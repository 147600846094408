$grey:#4d4d4d;
$grey1:#3c3c3c;
$black:#000;
$black1:#111;
$black2:#1e1e1e;
$shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
$shadow1: rgba(0, 0, 0, 0.07) 0px 1px 2px,
rgba(0, 0, 0, 0.07) 0px 2px 4px,
rgba(0, 0, 0, 0.07) 0px 4px 8px,
rgba(0, 0, 0, 0.07) 0px 8px 16px,
rgba(0, 0, 0, 0.07) 0px 16px 32px,
rgba(0, 0, 0, 0.07) 0px 32px 64px;
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;800&display=swap');

* {
  color: #fff;
  font-family: 'Catamaran', sans-serif;
  margin: 0;
  padding: 0;

  font-size: 1rem;
}

body {
  background-color: $black2;

  display: block;
}

.nav {
  &bar {
    background-color: $black2;

    right: 0;
    left: 0;
  }

  &_container {
    display: flex;
    width: 80%;
    margin: auto;
    padding: 1rem 2rem;
    align-items: center;
  }

  &_left,
  &_links {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &_expanded {

    width: 32vw !important;
    margin-top: 0.4rem !important;
    margin-bottom: 0 !important;

    font-size: 1.4rem !important;

  }

  &_mobile_flex {
    display: flex;
    flex-direction: column;
    width: fit-content !important;
    margin-left: auto !important;
    margin-right: 10vw !important;

  }

  &_mobile_flex>* {
    display: flex;
    flex-direction: column;
    width: fit-content !important;


  }

  &_logo {
    width: 13rem;
    cursor: pointer;
  }

  &_links {
    display: grid;
    grid-template-columns: 0.8fr 0.8fr 1.1fr 0.8fr 0.8fr 0.3fr;
    margin-left: auto;
    list-style-type: none;
    gap: 1rem;
    position: relative;
  }
&_languages{
  display: flex;
  align-items: center;
  justify-content: center;

}
&_language{
    font-size: 1.8rem;
      
}
&_language:nth-child(1){
  border-right: solid 1px #fff;
  padding-right: 1rem;
  margin-right:1rem;
}
  &_links_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;

  }

  &_links_mobile>* {
    width: 70vw;

    font-size: 1.6rem;
    list-style-type: none;

    margin: 0.5rem;

    border-radius: 0.5rem;
    text-align: end;
    padding: 0 1rem;
    background-color: $black2;
    cursor: pointer;
  }

  &_link {
    background-color: $grey1;
    padding: 0.4rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 0.5rem;
    transition: 0.3s;
  }

  &_link:hover {
    background-color: #8b8b99;
  }

  &_dropdown {
    display: grid;
    grid-column: 3/5;
    box-shadow: $shadow1;
    background-color: $grey;
    width: fit-content;
    z-index: 1000;
    position: absolute;
    top: 2rem;
    list-style-type: none;
  }

  &_option {
    padding: 0.5rem;
    padding-right: 2rem;
    cursor: pointer;
  }

  &_option:hover {
    background-color: #000;
  }

  &_burger {
    display: none;
    font-size: 1.4rem;
  }

  &_burger_detail {
    display: none;
    position: fixed;
    top: 6rem;

    right: 0;
    width: 100vw;

    background-color: $black1;
    z-index: 99999;

  }

  user-select: none;
}



.lang_dropdown{
    display: grid;
    grid-column: 6/6;
    box-shadow: $shadow1;
    background-color: $grey;
    width: fit-content;
    z-index: 1000;
    position: absolute;
    top: 2rem;
    list-style-type: none;
 
}
.lang_dropdown >*{
  text-align: center;

}


.intro {

  width: 100%;
  margin: auto;
  position: relative;


  &_image_pc,
  &_image_mobile {
    position: relative;
    z-index: 999;
    object-fit: contain;
    width: 100%;
    margin: auto;
  }

  &_image_mobile {
    display: none;
  }
}

.welcometext {

  margin: auto;

  position: absolute;
  top: 40%;
  left: 5%;
  z-index: 1000;
}

.cover_image {
  width: 100%;
  opacity: 0.5;
  z-index: 1000;
}

.sepparator {
  border-top: solid 1px #fff;
}

.latest {
  &_container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    //Temp
    margin-bottom: 5rem;
    margin-top: 5rem;
  }

  &_desc {
    width: 70%;
    opacity: 0.95;
    margin: auto;
    margin:1.5rem auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    
   background-color: #252222;
    padding:2rem;
  }
  &_desc h2{
    padding-bottom: 1rem;
  }

  &_btn {
    display: flex;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &_second {
    margin-top: 5rem;
  }

  &_h1 {
    font-weight: 800;
  }
}

.contact {
  &_question {
    margin-top: 5rem;
  }

  &_form {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  &_btn {

    width: fit-content;
    margin: auto;
    margin-top: 2rem;

  }

  &_error {
    display: none;
    width: fit-content;
    margin: auto;
    border: solid 1px red;
    padding: 1rem;
    margin: 1rem auto;
    font-size: 1.5rem;
  }
}

.news {
  &_boxes {
    width: 80%;
    margin: auto;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 5rem;

  }

  &_box {
    width: fit-content;
    padding: 0 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
   box-shadow: $grey 0px 0px 0px 2px inset, $grey1 10px -10px 0px -3px;
  }

  &_text {
    font-size: 1.7rem;
    text-align: center;

  }

  &_photo {
    width: 18rem;
    height: 11rem;
    object-fit: contain;
    opacity: 0.9;
    margin-bottom: 1rem;
  }

  &_tools {
    display: flex;
    gap: 1rem;
  }

  &_tools>* {
    font-size: 1.5rem;

  }
}

.detail {
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }

  &_img {
    width: 38rem;
    height: 22rem;
    margin: auto;
    object-fit: contain;
  }

  &_desc {
    width: 50rem;
    margin: auto;
  }
}

.discography {

  &_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap:5rem;
  width: 80%;
  margin: auto;
  margin-top:5rem;

  &_h2 {
    font-weight: 400;
    font-size: 1.6rem;
  }

  &_image {
    width: 12rem;
  }


  &_detail_container {
    width: 80%;
    margin: auto;
    padding-top: 7rem;
  }

  &_info {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

 

  &_detail_image {
    width: 15rem;
  }

  &_name {
    font-size: 2rem;
  }

  &_date {
    margin-top: -1rem;
    font-weight: lighter;
  }

  &_links {
    margin-top: 7rem;
    display: flex;

  }

  &_links>*>* {
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  &_desc {
    width: 40vw;
    margin-left: auto;
  }

  &_tracks {
    display: flex;
    flex-direction: column;

    padding: 1rem;
    margin-top: 4rem;
    border-radius: 5px;


  }

  &_track {
    font-size: 1.2rem;
    border-bottom: 1px solid #fff;
    padding: 1rem;
    padding-right: 3rem;

  }
}

.singles,
.soundtracks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 5rem;
  width: 80%;
  margin: auto;
  margin-top: 5rem;
  text-align: center;

  &_text {
    margin-top: 4rem;
  }
}

.compositions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10rem;
  align-items: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  margin-top: 5rem;

  &_image {
    width: 18rem;
  }

  &_h2 {
    font-weight: 400;
    text-align: center;
    font-size: 1.6rem;
  }

  &_box {
    cursor: pointer;
    width: fit-content;
  }

  &_detail_container {
    width: 80%;
    margin: auto;
    padding-top: 4rem;
  }

  &_detail_h2 {
    font-size: 1.6rem;
    margin-top: 1rem;
    width: 18rem;
  }

  &_grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }

  &_video {
    width: 50rem;
    height: 30rem;
    margin-top: 2rem;
    margin-bottom: 10rem;
  }

  &_performance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_desc_h2_p {
    margin-top: 5rem;
  }
}


h1 {
  font-size: 2.6rem;
  letter-spacing: 0.15rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.4rem;
  font-weight: 300;
}

p {
  font-size: 1.2rem;
}
.p{
  width: 90%;
}
.text_center {
  text-align: center;
}

button {
  background-color: $grey;
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  box-shadow: $shadow;
  transition: 0.3s;
  font-weight: 500;
  cursor: pointer;
}

label {
  margin-top: 1rem;
}

button:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.socialmedia {
  display: flex;
  justify-content: center;
  border-top: solid 1px #fff;
  width: fit-content;
  padding-top: 1rem;
  margin: auto;

}

.socialmedia>*>* {
  font-size: 2rem;
  margin: 0rem 1rem;
}

input,
select,
option,
textarea {
  background-color: $grey;
  outline: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: 0.5rem 1rem;
  resize: none;
  border: solid 1px transparent;
}

.adminlogin {
  display: flex;
  flex-direction: column;
  width: 25rem;
  margin: auto;
  margin-top: 20vh;
}

.admin {
  &btn {
    margin-top: 3rem;
  }
}

.toolbar {
  display: flex;
  justify-content: center;
}

.create_new {
  display: flex;
  flex-direction: column;
  width: 25rem;
  margin: auto;
}

input:focus {
  border: solid 1px #fff;
}

.about {
  &_desc {
    max-width: 80vw;
    margin: auto;
    margin-top: 4rem;
  }

  &_container {
    margin-bottom: 4rem;
  }

  &_photo {
    width: 15rem;
    float: right;
    padding: 1rem;
    margin-top: -4rem;

  }
}

@media(max-width:934px) {
  .discography_name {
    font-size: 1.6rem;
  }
  .nav_logo{
    
    width: 4rem;
    cursor: pointer;
 

  }
  .discography_date {
    font-size: 1.4rem;
  }

  .discography_desc {
    margin: 0;
  }

  .soundtracks {
    gap: 3rem !important;
  }

  .contact_error {
    font-size: 1rem;
  }

  .latest_desc {
    width: 70%;
  }

  .contact_form {
    width: 60vw;
  }

  .text_center {
    padding: 0 2rem;
  }

  .detail {
    &_desc {
      width: 80%;
      margin: auto;
    }
  }

  .singles,
  .soundtracks {
    grid-template-columns: 1fr 1fr !important;
  }

  .discography {
    grid-template-columns: 1fr 1fr !important;

    &_left {
      width: fit-content;
      margin: auto;
    }

    &_links {
      margin: 0;
      justify-content: center;
    }

    &_links>*>* {
      font-size: 1.6rem !important;
    }

    &_name,
    &_date {
      text-align: center;
    }

    &_detail_image {
      width: 16rem;
    }

    &_desc {
      margin: 0 !important;

      padding: 3rem 0;
    }

    &_links>*>* {
      font-size: 2rem;
      padding-top: 2rem;
    }
  }

  .discography_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }

  .discography_desc {
    width: 80vw !important;
    margin-top: 5rem;
  }

  .discography_tracks {
    margin: 0;
    padding-bottom: 5rem;
  }

  .compositions {
    &_video {
      width: 40rem;
      height: 25rem;
      margin-top: 2rem;
      margin-bottom: 10rem;
    }
  }
}

@media(max-width:730px) {
  .nav {
    &_container {
      justify-content: space-between;
    }

    &_links {
      display: none;
    }

    &_burger {
      display: block;
    }



  }

  .detail {
    &_img {
      width: 30rem;
      height: 20rem;

    }
  }

  h1 {
    font-size: 2rem;
  }

  .latest_container {
    width: 100%;

  }
  .latest_h1{
    font-size: 1.8rem;
  }
  .detail_desc{
    padding-bottom: 5rem;
  }

  .intro {
    &_image_mobile {
      display: block;
    }

    &_image_pc {
      display: none;
    }
  }

  .about_photo {

    margin: auto;
    margin-top: -30rem;
  }

  .about_desc {
    margin-top: 30rem;
    display: flex;
    flex-direction: column;
  }
  .news{
    &_boxes{
      display: grid;
    }
  }
}

@media (max-width:1233px) {
  .news {
    &_boxes {
      grid-template-columns: 1fr 1fr;
    }
  }

  .discography {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem;
    width: 90%;
  }

  .singles,
  .soundtracks {

    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem;
    width: 90%;

  }

  .compositions {
    grid-template-columns: 1fr 1fr;

    &_right {
      width: 40vw;
      margin-left: 5rem;
      margin-right: 1rem;
    }
  }
}

@media (max-width:792px) {
  .news {
    &_boxes {
      grid-template-columns: 1fr;
    }

    &_box {
      margin: auto;
    }
  }

  .navbar {
    position: fixed;
    z-index: 9999;
  }

  .contact_container,
  .about_container,
  .news_container,
  .detail_container,
  .discography_container,
  .discography_detail_container,
  .compositions_container,
  .compositions_detail_container,
  .soundtracks_container,.success,.tickets_container {
    padding-top: 7rem !important;
  }

  .intro {
    padding-top: 5rem;
  }

  .welcometext {
    top: 50%;
  }

  .compositions {
    grid-template-columns: 1fr;

    &_grid {
      grid-template-columns: 1fr;
      justify-content: center;
    }

    &_left {
      margin: auto;
    }

    &_right {
      width: 80vw;
      margin-left: 1rem;
      margin-top: 5rem;
    }

    &_video {
      width: 30rem;
      height: 20rem;
    }

    gap:2rem;

    &_detail_h2 {
      font-size: 1.4 rem;
      text-align: center;
    }
  }





}

@media(max-width:534px) {
  .detail_img {
    width: 20rem;
    height: 13rem;
  }
  .news_boxes{
    margin: 5rem auto;
  }
  .news_box{
    width: 70%;
    margin: auto;
  }
  .discography {
    grid-template-columns: 1fr 1fr !important;
    &_detail_image{
      width: 13rem;
    }
  }

  .soundtracks, .discography, .singles {
    grid-gap: 1rem !important;
    grid-auto-rows: 1fr;
    margin-top:7rem !important;


  }

  .soundtrack_box, .discography_box {
    width: 10rem;
    height: 20rem;
    margin: auto;
    margin-top:-6rem;
    justify-content: center;

  }

  .soundtrack_text, .discography_h2 {
    height: 2rem;
    font-size: 1.2rem;
   text-align: center;
  }

  .discography_image {
    width: 8rem;
    height: 8rem;
  }



  .compositions {

    &_video {
      width: 20rem;
      height: 14rem;
    }

    &_image {
      width: 14rem;
      margin: auto;
    }

    &_left {
      display: flex;
      flex-direction: column;
    }

    &_box {
      display: flex;
      flex-direction: column;

      margin: auto;
      gap: 1rem;
    }

  }
  .latest{
    &_desc h2{
      font-size: 1.6rem;
    }
  }
  .about {
    &_photo {
      width: 10rem;
    }
  }
}



//Tickets
.tickets_container{
  padding-top: 3rem;
}
.ticket_input{
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}
.concert_photo{
  max-width: 38rem;
  opacity: 0.9;
}
.concert_name{
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.ticket_intro_container{
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: auto;
  margin-top: 5rem;
  gap:6rem;
  padding-bottom: 20rem;
  z-index: 99999;
}
.ticket_left{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:1rem;
}
.ticket_left label{
  font-weight: bold;
}
.ticket_input input{
  width: 100%;
}
.ticket_input{
  width: 90%;
}
#stripe{
  display: none;
}
.concert_desc{
  max-width: 50rem;
  margin: auto;
  margin-top:2rem;
}
.ticket_desc{
  text-align: justify;
  width: 100%;
}
.documents{
  position: absolute;
  right: 10000vh;
}
@for $i from 0 through 15 {
.document#{$i}{
  position: relative;
  width: 366px;
  display: flex;
  margin: auto;
  text-align: center;
  flex-direction: column;
  align-items: center;
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  padding: 2rem 2rem;
  padding-bottom: 4rem;
  color:#fff;
  font-family: sans-serif !important;
}
}
#qrcode{
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
  width: 3rem;
}
.name{
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top:1rem;
}

.ticket_downloaded{
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;
}
.getticket_email{
  width: fit-content;
  margin: auto;
  margin-bottom: 3rem;
}
.success{
  text-align: center;
}
.start{
 
  font-weight: 400;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.email_ticket{
  margin-top:1rem;
}
.door{
  font-size: 1.5rem;
  margin-top: 1rem;
}

//Loader
.loader{
  z-index: 9999;
}
.lds-ellipsis {
  display: inline-block;
  position: fixed;
  top:40%;
  left:46%;
  width: 80px;
  height: 80px;
  z-index: 9999;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  z-index: 9999;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.loaderbck{
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(15, 13, 13);
  opacity: 0.9;
  z-index: 999;
}

.successpic{
  width: 20rem;
  margin: auto;
  display: flex;
  align-self: center;
}

@media (max-width:1200px) {
  .concert_photo{
    width: 30rem;
  }
}

@media (max-width:980px) {
  .concert_photo{
    width: 25rem;
  }
  .ticket_intro_container{
    width: 90%;
    grid-template-columns: 0.5fr 0.8fr;
    gap:2rem;
  }
  .concert_desc{
    width: 80%;
  }
}

@media (max-width:745px) {
  .ticket_intro_container{
    width: 90%;
    grid-template-columns: 1fr;
    gap:2rem;
  
   
  }
  .ticket_input{
    width: 80%;
    margin: auto;
  }
  .ticket_input input{
    width: 95%;
  }
}

@media (max-width:471px) {
  .concert_photo{
    max-width: 20rem;
  }
}


.emailhold{
  padding-bottom:0.5rem;
}
.whichticket{
  position: absolute;
  top:3%;
  right: 5%;
  font-weight: bold;

}

.errormenu{
  padding: 0.3rem;
  border: solid 1px red;
  width: fit-content;
  margin: auto;
  margin-top:1rem;
}

.buttons_success{
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  gap:2rem;
}

@media (max-width:510px) {
  .buttons_success{
    flex-direction: column;
    gap:1rem;
    padding: 2rem;
  }
  .getticket_email{
    margin: 0;
  }
}

.notif{
  text-align: center;
  padding: 0.2rem;
  max-width: 40vw;
  margin: auto;
  border: solid 1px green;
  margin: 1rem auto;

}

@media (max-width:600px) {
  .notif{
  max-width: 80%;
  
  }
}
@media(max-width:676px){
  .lds-ellipsis{
    left:43%;
  }
}

@media (max-width:507px) {
  .lds-ellipsis{
    left:40%;
  }
}

@media(max-width:775px){
  .notif{
    margin-top: 0;
  }
}